@import url("./assets/fonts/stylesheet.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply font-mono;
}

.bg-primary {
  background-color: var(--primary-color);
}

.border-lipad-green {
  border-color: red;
}

.text-primary {
  color: var(--primary-color);
}

.ring-lipad-green {
  --tw-ring-color: var(--primary-color);
}

/* Spinner animation */
.lds-ring {
  display: flex;
  justify-content: center;
  align-items: center;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 16rem;
  height: 16rem;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #263470 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
